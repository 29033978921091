.iphone-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.iphone-warning-card {
  width: 316px;
  height: 345px;
  background-color: #fbfbfb;
  color: black;
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 14px 0;
  outline: 0;
}

.iphone-warning-close-icon {
  align-self: flex-end;
  cursor: pointer;
}

.iphone-warning-top-section h2 {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.004em;
  line-height: 27px;
  color: #160f3e;
  text-align: left;
  margin: 0 0 14px 0;
}

.iphone-warning-top-section p {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #160f3e;
  text-align: left;
  margin: 14px 0;
}

.iphone-warning-card hr {
  border: 0;
  height: 0;
  border-top: 1px solid #a1a1a1;
  width: 100%;
  margin: 0 0 30px 0;
}

.iphone-warning-bottom-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iphone-warning-bottom-section p {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.007em;
  color: #160f3e;
  margin: 0 10px;
}

.iphone-warning-url-bttn {
  width: 208px;
  height: 36px;
  background: #ef4e23;
  border-radius: 17px;
  padding: 8px 20px;
  margin: 30px 0 20px 0;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.005em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
