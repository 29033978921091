@import "../../assets/styles/variables";

.container {
  // main app container
  max-width: 3840px;
  width: 100vw;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.rounded {
  border-radius: $border-radius;
}

.box {
  padding: $box-padding;
}
