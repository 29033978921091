@import-normalize;

@import "assets/styles/mixins";
@import "assets/styles/text";
@import "assets/styles/containers";
@import "assets/styles/buttons";
@import "assets/styles/formFields";

html {
  font-size: 16px;
  height: -webkit-fill-available;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  // height: 100vh;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-size: 100%;
  overflow: hidden;
  background-color: $color-primary-dark;
}

#root {
  height: 100%;
}

html, body {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

a {
  &:hover {
    cursor: pointer;
  }
}

// HELPERS

.mobile-only {
  display: initial;

  @include tablet-up {
    display: none;
  }
}

.right-align {
  text-align: right;
}

.flex {
  display: flex;
}

.color-secondary-light {
  background-color: $color-secondary-light;
}

.color-tertiary {
  background-color: $color-tertiary;
}

.bold {
  font-weight: bold;
}
