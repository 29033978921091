.mobile-menu {
  height: 48px;
  width: 100%;
  background-color: #160f3e;
  box-shadow: 0 1px 0 0 rgba(98, 93, 193, 0.8);
  border-radius: 0 0 17px 17px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
