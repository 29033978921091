@import "assets/styles/variables";

.Checkbox {
  &.Mui-checked {
    svg {
      fill: $color-secondary-light;
    }
  }
}

.Checkbox-label {
  span {
    font-family: $font-family;
    font-size: 0.75rem; // 12px
    line-height: 1.167; // 14px
  }
}
