@import "../../assets/styles/containers";
@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

$contracted-channels-width: 42px;

.EventfeedScreen {
  border-top-right-radius: $border-radius;
  background-color: $color-primary;
  height: 100%;
  min-width: 100vw;
  display: flex;
  color: white;

  &.box {
    padding-left: 0;
    padding-bottom: 0;
    padding-right: $scroll-padding;
  }

  @include tablet-up {
    width: 440px;
    min-width: unset;
  }
}

.EventfeedScreen-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @include tablet-up {
    justify-content: initial;
  }
}

.EventfeedScreen-fr-view {
  background-color: $color-greyscale-white;
  border: 1px solid #CCCCCC;
  padding: 1rem 10px;
  display: flex;
  justify-content: center;
  min-width: 266px;

  @-moz-document url-prefix() {
    margin-right: 7px;
  }
}

.EventfeedScreen-post {
  color: initial;
  padding-bottom: 1.125rem;
  width: 262px;
  margin: auto;
}

.EventfeedScreen-channels {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 138px;
  padding: 0 0.625rem 0 10px;
  margin-right: 10px;
  margin-left: 0;

  &.contracted {
    padding-right: 0;
    padding-left: 0;
    width: $contracted-channels-width;

    .EventfeedScreen-channelButton {
      writing-mode: vertical-rl;
    }

    .EventfeedScreen-channel {
      align-items: center;
    }

    svg {
      margin: 0 auto;
    }

    .EventfeedScreen-expanderIconWrap {
      justify-content: center;
    }
  }

  &.EventfeedScreen-channels-transitioning {
    .EventfeedScreen-channelButton {
      transform: translateX(-2rem);
      opacity: 0;
    }

    .EventfeedScreen-channels-inner {
      overflow: hidden;
    }
  }

  @include tablet-up {
    position: relative;
    display: block;
    width: 146px;
    padding-left: 0.625rem;
    padding-top: 0.625rem;
    padding-right: $scroll-padding;
    height: 100%;
  }
}

.EventfeedScreen-channels-wrapper {
  z-index: 1;
  padding-bottom: 10px;
  position: relative;

  @include tablet-up {
    height: 100%;
  }
}

$expander-icon-height: 46px;
.EventfeedScreen-expanderIconWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: $expander-icon-height;
}

.EventfeedScreen-channels-background {
  background-color:$color-primary-dark;
  border-top-right-radius: $border-radius;
  width: 138px;
  margin-right: 10px;
  transition: transform .2s;

  position: absolute;
  height: 100%;

  &.contracted {
    // triggered through mobile only UX
    transform: translateX(-96px);
    transform-origin: left;
  }

  @include tablet-up {
    width: 146px;

    &.contracted {
      transform: none;
    }
  }
}

.EventfeedScreen-channels-inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: calc(100% - #{$expander-icon-height});
  padding-right: 0;
  width: 100%;
  transition: transform .2s .4s;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet-up {
    height: 100%;
    padding-bottom: 0;
    overflow: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      display: initial;
    }
  }
}

.EventfeedScreen-channels-inner input {
  user-select: auto;
}

.EventfeedScreen-channel {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;

  @include tablet-up {
    margin: 0;
  }
}

.EventfeedScreen-channelButton {
  display: inline-block;
  color: $color-text-stencil;
  background-color: $color-tertiary;
  padding: 0.5rem .5rem;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.125rem;
  opacity: 1;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include transition(transform .3s, opacity .3s);

  @include tablet-up {
    padding: .5rem .75rem;
  }
}

.EventfeedScreen-channelButton-selected {
  background-color: $color-secondary-light;
}

.EventfeedScreen-posts {
  height: calc(100% - #{$box-padding});
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  position: relative;
  overflow: auto;
  margin-top: 10px;
  padding-right: 0;
  width: calc(100vw - #{$contracted-channels-width} - #{$scroll-padding});

  .fr-view {
    width: 240px;

    p:first-of-type {
      margin-top: 0;
    }
  }

  @include tablet-up {
    min-width: 280px;
    width: 100%;
    padding-right: 10px;
    height: calc(100% - #{$box-padding});
    align-items: initial;
  }
}
