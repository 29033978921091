$font-family: "Montserrat", sans-serif;
$border-radius: 17px;
$banner-height: 60px;
$stage-header-height: 5.125rem; // 82px
$button-width: 7.5rem; // 120px
$scroll-padding: 4px; // distance from scrollbar to edge of container
$box-padding: 0.625rem; // 10px

// Base colors

$color-primary: #292663;
$color-primary-dark: #160f3e;
$color-primary-light: #432a74;

$color-secondary: #ef4e23;
$color-secondary-dark: #c3161c;
$color-secondary-light: #fbae17;

$color-tertiary: #cbc9ea;
$color-tertiary-dark: #4e496d;
$color-tertiary-light: #fdd992;

$color-greyscale-white: #ffffff;
$color-greyscale-ceramic: #f6f6f9;
$color-greyscale-clay: #c8c8c8;
$color-greyscale-black: #000000;

// Text colors

$color-text-highlight: $color-secondary-light;
$color-text-default: $color-greyscale-white;
$color-text-light: $color-tertiary-light;
$color-text-stencil: $color-primary-dark;
$color-text-muted: $color-tertiary;
$color-text-strong: $color-secondary;

// Text sizes
$text-hero-font-size: 1.5rem; // 24px
$text-hero-line-height: 1.209; // 29px

// Breakpoints
$xs: 0px;
$sm: 600px;
$md: 960px;
$lg: 1280px;
$xl: 1920px;

$tablet: 768px;
