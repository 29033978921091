// Froala default font css
.fr-box.fr-inline, .fr-element.fr-view {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.fr-view {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

.fr-box.fr-basic .fr-element {
  width: 270px;
  margin: 1rem auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
}

.fr-transparent {
  border-color: transparent !important;
}
