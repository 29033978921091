@import "../../assets/styles/variables";
h1 {
  font-size: 1.5rem; // 24px
  font-weight: normal;
}

h2 {
  font-size: 1.313rem; // 21px
  font-weight: normal;
}

h3 {
  font-size: 1rem; // 16px
}

h4 {
  font-size: 0.75rem; // 12px
}

h5 {
  font-size: 0.875rem; // 14px
  font-weight: bold;
}

h6 {
  font-size: 0.875rem; // 14px
}

.text-ui {
  font-size: 0.75rem; // 12px
  line-height: 1.167; // 14px
}

.text-ui-important {
  @extend .text-ui;
  font-weight: bold;
  color: $color-text-highlight;
}

.text-tiny {
  font-size: 0.5625rem; // 9px
  line-height: 1; // 10px
}

.text-small {
  font-size: 0.625rem; // 10px
  line-height: 1.2; // 12px
}

.text-medium {
  font-size: 0.875rem; // 14px
  line-height: 1.214; // 17px
}

.text-large {
  font-size: 1.125rem; // 18px
  line-height: 1.167; // 21px
}

.text-hero {
  font-size: $text-hero-font-size;
  line-height: $text-hero-line-height;
}
