@import "assets/styles/variables";

.btn, button.btn {
  @extend .rounded;
  text-transform: none;
  width: $button-width;
  color: $color-text-stencil;
  background-color: $color-secondary-light;

  &:hover {
    background-color: lighten($color-secondary-light, 15%);
  }
}

.btn-secondary {
  @extend .rounded, .btn;
  color: $color-text-default;
  background-color: $color-secondary;

  &:hover {
    background-color: lighten($color-secondary, 15%);
  }
}

.btn-tertiary, button.btn-tertiary   {
  @extend .rounded, .btn;
  width: $button-width;
  background-color: $color-tertiary;

  &:hover {
    background-color: lighten($color-tertiary, 5%);
  }
}
