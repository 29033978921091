@import "../../assets/styles/variables";
@import "../../assets/styles/text";

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin animation($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -ms-animation: $args;
  -o-animation: $args;
  animation: $args;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin custom-scrollbar {
  /* total width */
  div {
    // firefox
    scrollbar-color: rgba(234,233,239,0.82) transparent;
    scrollbar-width: thin;

    // everything else
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    // /* The track NOT covered by the handle.
    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    /* handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: rgba(234,233,239,0.82);
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      // background: #666;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display:none;
    }

    /* Corner */
    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    /* Resizer */
    // &::-webkit-resizer {

    // }
  }
}

// Media queries

@mixin xs-up {
  @media (min-width: #{$xs}) {
    @content;
  }
}
@mixin sm-up {
  @media (min-width: #{$sm}) {
    @content;
  }
}
@mixin md-up {
  @media (min-width: #{$md}) {
    @content;
  }
}
@mixin lg-up {
  @media (min-width: #{$lg}) {
    @content;
  }
}
@mixin xl-up {
  @media (min-width: #{$xl}) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

// text helpers
@mixin text-hero {
  font-size: $text-hero-font-size;
  line-height: $text-hero-line-height;
}
